


export default [
   
    { id: 'news', name: 'News' },
   
    { id: 'event', name: 'Event' },

    { id: 'interview', name: 'Interview' },
    
    { id: 'report', name: 'Report' },
   
    { id: 'guide', name: 'Guide' },

    { id: 'review', name: 'Review' },

    { id: 'other', name: 'Other' },
]
