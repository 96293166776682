
import { useGetList } from "react-admin"

const CompanyPurchases = ({filter}) => {

    return null;

    // const {data, ids, loading, error} = useGetList("upgrades", { page: 1, perPage: 10 })

    // if(loading || error){
    //     return null
    // }

    // return ids.map(id => {

    //     const item = data[id]

    //     if(filter && !filter(item)){
    //         return null
    //     }

    //     return item.id

    // })

}


export default CompanyPurchases